<template>
  <div class="vanguardTaskApplicants">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :exportParams="exportParams"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="previous"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="活动名称" v-model="activityName" disabled />
        <v-input label="姓名" v-model="searchParam.userName" />
        <v-input label="联系电话" v-model="searchParam.applicantsPhone" />
        <v-select
          clearable
          :options="isSign"
          v-model="searchParam.isSignName"
          @change="$refs.list.search()"
          label="是否签名"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.signInNum > 0"
          text="编辑积分"
          type="text"
          @click="toPoint(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { activityApplicantsList, exportApplicantsUrl } from "./api.js";
import { isSignMap, isSign } from "./map";
import { createImgVNode } from "@/utils/utils.js";
export default {
  name: "vanguardTaskApplicants",
  data() {
    return {
      isSign,
      activityCategory: undefined, // 活动类型 20-时间银行 19-幸福学堂 1-热门活动
      exportParams: {
        url: exportApplicantsUrl,
        methods: "post",
        params: {
          activityId: this.$route.query.id,
          joinUserStatus: 0,
        },
      },
      scheduleId: "",
      activityName: this.$route.query.name,
      searchParam: {
        activityId: this.$route.query.id,
        userName: "",
        applicantsPhone: "",
        isSignName: "",
      },
      tableUrl: activityApplicantsList,
      headers: [
        {
          prop: "userName",
          label: "报名人",
        },
        {
          prop: "applicantsPhone",
          label: "联系方式",
          align: "center",
        },
        {
          prop: "appliedTime",
          label: "报名时间",
        },
        {
          prop: "scheduleStartDate",
          label: "场次开始时间",
        },
        {
          prop: "scheduleEndDate",
          label: "场次结束时间",
        },
        {
          prop: "isSignName",
          label: "是否签字",
          formatter: (row, prop) => {
            return isSignMap[row.isSignName];
          },
        },
        {
          prop: "signatureImage ",
          label: "签名照片",
          formatter: (row, prop) => {
            if (row.isSignName == 1) {
              return createImgVNode(this, row, prop);
            } else {
              return "";
            }
          },
        },
      ],
    };
  },
  created() {
    this.activityCategory = Number(this.$route.query.type);
    this.scheduleId = this.$route.query.scheduleId;
    this.$setBreadList("参与人列表");
  },
  methods: {
    previous() {
      this.$router.go(-1);
    },

    toPoint(row) {
      this.$router.push({
        name: "activityPointForm",
        query: {
          recordId: row.recordId, // 记录id
          scheduleId: row.scheduleId, // 场次id
          activityId: this.$route.query.id, // 活动id
          applicantsId: row.applicantsId, // 参与人id
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.vanguardTaskApplicants {
  box-sizing: border-box;
  height: 100%;
  .create-dialog {
    ::v-deep > .el-dialog {
      width: 430px;
    }
    .wrap {
      display: flex;
      .btn {
        margin-left: 20px;
      }
    }
    .info-card {
      border-radius: 8px;
      padding: 12px;
      font-size: 14px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 10%);
      > p {
        margin-bottom: 10px;
        > span {
          display: inline-block;
          width: 80px;
          text-align: left;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
